// getUserData.js
'use strict';
import { Collection, List } from 'immutable';
import createCachedSelector from '../resource/createCachedSelector.js';
import { getMaskedUsername } from '../resource/getMaskedUsername.js';
import { getHashColor } from '../resource/getHashColor.js';
import { localeConvertor } from '../resource/i18n.js';
import { getI18nInstance } from '../resource/i18n.js';

const defaultList = new List();

/**
 * Select user data by data key
 * @kind selector
 * @param {Immutable.Map} state - root state.
 * @param {string} id - user id.
 * @param {string} dataKey - select data key.
 * @return {any} The selected user data.
 */
const getUserData = createCachedSelector(
  (state, id) => state.getIn(['users', id]),
  (state, id, dataKey) => dataKey,
  state =>
    state.getIn(['operations', 'config', 'language']) ||
    getI18nInstance()?.i18n.language,
  (user, dataKey, currentLanguage) => {
    let result = undefined;
    // to normalize zh-tw to zh-TW
    const currentLocale = localeConvertor({ locale: currentLanguage });
    if (user) {
      if (dataKey === 'maskedUsername') {
        result = getMaskedUsername({ username: user.get('username') });
      } else if (dataKey === 'avatarColor') {
        result = getHashColor({ hashStr: user.get('id') });
      } else if (dataKey === 'age') {
        const birthdate = user.get('birthdate');
        result = birthdate
          ? new Date().getFullYear() - new Date(birthdate * 1000).getFullYear()
          : null;
      } else if (dataKey === 'originalBiography') {
        result = user.get('biography');
      } else if (dataKey === 'biography') {
        result =
          user.getIn(['i18nBiography', currentLocale]) || user.get('biography');
      } else if (dataKey === 'livestreamCardBadges') {
        const badges = user.get('badges') || defaultList;
        result = badges.filter(badge => badge === 'new').toSet();
      } else {
        result = user.get(dataKey);
      }
    }

    return result instanceof Collection ? result.toJS() : result;
  }
)((state, id, dataKey) => `${id}:${dataKey}`);

export default getUserData;
